// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query WebAuthnChallenge($email: String!) {
    publicKeyCredentialRequestOptions: webauthnChallenge(email: $email) {
      assertion {
        challenge
        timeout
        rpId
        allowCredentials { id, type }
      }
      key
    }
  }`;

export default QUERY;
